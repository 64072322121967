/**
 * placeholder-link
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-placeholder-link {
    padding: 0;
    .text {
        position: relative;
        right: -8px;
        @include prefixer(transition, 0.2s);
    }
    .anticon {
        margin-left: 2px !important;
        opacity: 0;
        @include prefixer(transition, 0.15s 0s);
    }
    &.ant-btn-button {
        padding: 0 1px 0 2px;
        .anticon {
            margin-left: 1px !important;
        }
    }
    &:hover {
        .text {
            right: 0;
        }
        .anticon {
            opacity: 1;
            @include prefixer(transition, 0.15s 0.05s);
        }
    }
    &:disabled:hover {
        .text {
            right: -8px;
        }
        .anticon {
            opacity: 0;
        }
    }
}