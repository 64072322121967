/**
 * item editor
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-item-editor-model {
    &.c-item-editor-iframe {
        .ant-modal-body {
            padding: 0;
        }
        .c-fit-height-modal-inner {
            position: relative;
        }
        .c-item-editor-iframe-loading {
            @include position(absolute, 0 0 0 0, 2);
            margin: auto;
            background-color: #f0f2f5;
            &.off {
                display: none;
            }
            img {
                display: block;
                @include position(absolute, 50% 50% n n);
                width: 60px;
                height: 60px;
                margin: -75px 0 0 -30px;
                background: transparent;
            }
            span {
                position: absolute;
                @include position(absolute, 50% 50% n n);
                width: 100px;
                margin: 0 0 0 -50px;
                font-family: Arial;
                font-size: 14px;
                text-align: center;
                text-indent: 8px;
                color: #666;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
            }
        }
        iframe {
            display: block;
            width: 100%;
            height: 500px;
            min-height: 100%;
            max-height: 100%;
            border: none;
        }
    }
}