/**
 * geolocation point
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-geolocation-point-input {
    .ant-input-group-addon {
        &:hover {
            background-color: #f5f5f5;
        }
        >span {
            display: block;
            width: 40px;
            margin: -5px -11px;
            cursor: pointer;
        }
    }
}

.c-geolocation-point {
    .current {
        float: left;
        margin-bottom: 12px;
        height: 32px;
        line-height: 32px;
        white-space: nowrap;
        button {
            margin-left: 10px;
        }
    }
    .search {
        float: right;
        margin-bottom: 12px;
        .search-layer {
            @include position(absolute, 55px 0 n n, 100);
            width: 100%;
            height: calc(100% - 55px);
            overflow: hidden;
            pointer-events: none;
        }
        .search-panel {
            @include position(absolute, 0 n n 0, 2);
            width: 260px;
            max-width: 50%;
            height: 100%;
            background: #fff;
            box-shadow: rgba(0, 0, 0, 0.3) -3px 0 15px;
            @include prefixer(transform, translateX(0));
            @include prefixer(transition-duration, 0.3s);
            pointer-events: all;
            &.off {
                @include prefixer(transform, translateX(120%));
            }
        }
        .search-close {
            @include position(absolute, 50% -40px);
            width: 40px;
            height: 60px;
            margin-top: -30px;
            overflow: hidden;
            .anticon-double-right {
                @include position(absolute, 5px n n -60%);
                width: 50px;
                height: 50px;
                font-size: 16px;
                line-height: 50px;
                text-indent: -18px;
                color: #999;
                background: #fff;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.3) -3px 0 15px;
                cursor: pointer;
                svg {
                    vertical-align: inherit;
                }
            }
            .anticon-double-right:hover {
                color: rgba(0, 0, 0, 0.65);
                background: #eee;
            }
        }
        .search-top {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 32px;
            padding: 12px 12px 10px;
            box-sizing: border-box;
        }
        .search-result {
            @include position(absolute, 0 0);
            width: 100%;
            height: 100%;
            padding: 52px 0 10px 10px;
            box-sizing: border-box;
            >div {
                height: 100%;
                overflow-y: scroll;
                >div {
                    width: 100%;
                    border: none !important;
                    >div {
                        // 取消页脚外链
                        >a {
                            display: none;
                        }
                        // 页码
                        >div {
                            p {
                                padding: 0 2px 2px !important;
                                span {
                                    padding-right: 2px;
                                }
                            }
                        }
                    }
                }
            }
            ol {
                li {
                    margin-bottom: 4px !important;
                    // 内容
                    >div {
                        padding: 2px 6px 1px 5px !important;
                        &:hover {
                            background-color: #fbfbfb;
                        }
                        // 取消项目外链
                        a {
                            display: none;
                        }
                        // 取消粗体
                        b {
                            font-weight: normal !important;
                        }
                        // 屏蔽电话
                        div {
                            display: none;
                        }
                        div:nth-child(1),
                        div:nth-child(2) {
                            display: block;
                        }
                        // 标题颜色
                        div:nth-child(1) span {
                            color: #33c !important;
                        }
                        // 不显示“地址:”
                        div:nth-child(2) b {
                            display: none;
                        }
                    }
                }
            }
        }
        .search-mask {
            @include position(absolute, 0 0);
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.05);
            pointer-events: all;
            &.off {
                display: none;
            }
        }
    }
    iframe {
        width: 100%;
        height: 500px;
        @include prefixer-val(max-height, calc(100% - 65px));
        border: none;
    }
}