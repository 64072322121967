/**
 * geolocation point
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-china-address {
    &.show-default-city {
        .ant-cascader-picker {
            width: calc(100% - 110px);
        }
        .ant-btn {
            min-width: 0;
            width: 100px;
            margin-right: 0;
            margin-left: 10px;
            height: 26px;
        }
    }
}