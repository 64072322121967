/**
 * input range
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-input-range {
    display: inline-block;
    .ant-input-affix-wrapper {
        float: left;
        @include prefixer-val(width, calc(50% - 9px));
        .ant-input {
            float: none;
            width: 100%;
        }
        .ant-input-suffix {
            font-size: 12px;
            margin-right: -4px;
        }
    }
    .ant-input {
        float: left;
        @include prefixer-val(width, calc(50% - 9px));
    }
    .c-input-range-sep {
        float: left;
        width: 16px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
    }
    &.c-input-range-sep-big {
        .ant-input {
            @include prefixer-val(width, calc(50% - 12px));
        }
        .ant-input-affix-wrapper {
            @include prefixer-val(width, calc(50% - 12px));
            .ant-input {
                width: 100%;
            }
        }
        .c-input-range-sep {
            width: 22px;
            font-weight: normal;
        }
    }
    &.c-input-range-align-center {
        .ant-input {
            text-align: center;
        }
    }
}