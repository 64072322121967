/**
 * select-box
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-select-box {
    display: inline-block;
    position: relative;
    min-width: 240px;
    height: 38px;
    max-width: 100%;
    padding-left: 15px;
    margin-bottom: 5px;
    line-height: 36px;
    font-size: 15px;
    white-space: nowrap;
    border: #d9d9d9 1px solid;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.02) 1px 2px 2px;
    overflow: hidden;
    cursor: pointer;
    @include prefixer(user-select, none);
    transition: box-shadow 0.3s;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.08) 1px 2px 2px;
    }
    .title {
        font-weight: bold;
    }
    .tips {
        padding-left: 20px;
        padding-right: 20px;
        color: #999;
    }
    .anticon-check {
        display: none;
    }
    &.checked {
        border: #ff7875 1px solid;
        box-shadow: rgba(0, 0, 0, 0.01) 1px 2px 2px;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.08) 1px 2px 3px;
        }
        .anticon-check {
            display: block;
            @include position(absolute, n n 0 0);
            width: 11px;
            height: 12px;
            font-size: 12px;
            color: #fff;
            &::after {
                @include position(absolute, n n 0 0, 1);
                width: 0;
                height: 0;
                border-bottom: 18px solid #ff4848;
                border-left: 18px solid transparent;
                content: " ";
            }
            svg {
                position: relative;
                z-index: 2;
            }
        }
    }
    &:active {
        background-color: #fcfcfc;
    }
    &.locked {
        cursor: not-allowed;
        &.checked {
            .anticon-check::after {
                border-bottom-color: #ffaaaa;
            }
        }
        &:active {
            background: none;
        }
    }
}