/**
 * content-box
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-content-box {
    padding: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 2px;
    &:last-child {
        margin-bottom: 0;
    }
}
