/**
 * rich-table
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-rich-table {
    position: relative;
    .ant-table {
        thead {
            th {
                &.ant-table-column-has-sorters {
                    transition: none 0s;
                    @include prefixer(transition, background 0.3s ease);
                }
                &.ant-table-column-sort {
                    border-bottom: rgba(#1890ff, 0.75) 1px solid;
                }
                .c-table-title-helper {
                    .anticon-question-circle {
                        margin-left: 3px;
                        color: #a6a6a6;
                    }
                }
            }
        }
        .ant-table-title {
            padding: 8px;
        }
        .ant-table-cell {
            button+button {
                margin-left: 6px;
            }
        }
    }
    .c-expand-ellipsis {
        position: relative;
        .trigger {
            @include position(absolute, 0 n n 0);
            width: 26px;
            max-width: 50%;
            height: 100%;
            text-align: center;
            line-height: 45px;
            background-color: #fff;
            border-left: #e6e6e6 1px solid;
            cursor: pointer;
        }
        .ant-table-expanded-row-fixed+.trigger {
            display: none;
        }
    }
    .c-resizeable-head {
        overflow: visible;
        background-clip: padding-box;
        .c-resizable-handle {
            @include position(absolute, n n 0 -6px, 1);
            width: 12px;
            height: 100%;
            cursor: col-resize;
        }
    }
    .c-table-title {
        @include flexbox(flex, space-between flex-start);
        .c-table-title-content {
            padding-right: 5px;
        }
        .c-table-title-tools {
            @include flexbox(flex, flex-end, n wrap);
            margin: 0 -6px -6px 0;
            min-width: 70px;
            .ant-btn-sm {
                height: 26px;
                padding: 0 5px;
                margin: 0 6px 6px 0;
                font-size: 16px;
            }
            .c-table-full-export {
                position: relative;
                top: -2px;
            }
        }
    }
    .c-table-summary {
        text-align: center;
    }
}

.c-rich-table-ellipsis-floating {
    @include position(absolute, 0 0, 3);
    max-width: 500px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(#000, 0.2);
    .anticon-close {
        @include position(absolute, -13px n n -13px);
        width: 26px;
        height: 26px;
        padding: 5px 0 0;
        color: #fff;
        font-size: 16px;
        text-align: center;
        line-height: 0;
        background-color: #999;
        border-radius: 50%;
        @include transition;
        &:hover {
            background-color: #666;
        }
    }
    .inner {
        padding: 11px 16px 12px 8px;
    }
}

.c-rich-table-row-lid {
    overflow: visible;
    z-index: 3;
    .c-rich-table-row-lid-inner {
        @include position(absolute, 0 0 n n, 1);
        width: 100%;
        height: 100%;
        background-color: #fff
    }
    .c-rich-table-row-lid-content {
        @include position(absolute, 50% 50% n n);
        max-width: 100%;
        min-width: 90%;
        @include prefixer(transform, translate(-50%, -50%));
        word-break: break-all;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}