/**
 * date picker
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-date-picker {
    width: 100%;
    &.ant-picker-range {
        &:hover,
        &.ant-picker-focused {
            .ant-picker-range-separator {
                &::after {
                    border-left: #40a9ff 1px solid;
                }
                .anticon {
                    opacity: 0;
                }
            }
        }
        .ant-picker-range-separator {
            position: relative;
            padding: 0 1px;
            &::after {
                @include position(absolute, -7px 10px);
                width: 0;
                height: 30px;
                border-left: transparent 1px solid;
                content: " ";
                transition: 0.3s;
            }
            .anticon {
                opacity: 1;
                transition: 0.3s;
            }
        }
        .ant-picker-active-bar {
            height: 3px;
        }
    }
}

.c-date-picker-dropdown {
    .ant-picker-footer-extra {
        display: none;
    }
    &.c-date-picker-limit-days {
        .ant-picker-footer {
            position: relative;
            height: 82px;
            .ant-picker-footer-extra {
                display: block;
                @include position(absolute, n 0 0 n);
                width: 100%;
                border-top: 1px solid #f0f0f0;
                border-bottom: none;
            }
        }
    }
}

.c-date-time-range-picker {
    min-width: 330px;
    &.no-second {
        min-width: 310px;
    }
    &:hover,
    &.ant-picker-focused {
        .ant-picker-range-separator {
            &::after {
                border-left: #40a9ff 1px solid;
            }
            .anticon {
                opacity: 0;
            }
        }
    }
    .ant-picker-range-separator {
        position: relative;
        padding: 0 1px;
        &::after {
            @include position(absolute, -7px 10px);
            width: 0;
            height: 30px;
            border-left: transparent 1px solid;
            content: " ";
            transition: 0.3s;
        }
        .anticon {
            opacity: 1;
            transition: 0.3s;
        }
    }
}

.c-hour-range-picker {
    position: relative;
    &.show-minute {
        .ant-picker-input {
            &::after {
                content: "";
            }
            input {
                letter-spacing: 1px;
                text-indent: 0px;
            }
        }
    }
    .ant-picker-range-separator {
        padding: 0;
    }
    .ant-picker-input {
        &::after {
            @include position(absolute, 0 n n 50%);
            margin-right: -14px;
            content: "点";
            pointer-events: none;
        }
        input {
            text-align: center;
            text-indent: -26px;
        }
    }
}
.c-hour-range-popup {
    .ant-picker-panel-container {
        min-width: 110px;
    }
    .ant-picker-panels {
        width: 100%;
    }
    .ant-picker-panel {
        width: 100%;
    }
    .ant-picker-time-panel-column {
        overflow-y: scroll;
        &::after {
            height: 15px;
        }
    }
}