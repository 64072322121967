/**
 * table tool filter
 * @author Tevin
 */

@import "../../common/sassMixin";


.c-table-filter-drop {
    padding-top: 0;
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner-content {
        padding: 0;
        border: #eee 1px solid;
    }
    .c-table-filter-drop-inner {
        height: 500px;
        .filter-title {
            line-height: 24px;
            padding: 8px 12px 8px;
            border-bottom: 1px solid #f0f0f0;
            .ant-btn {
                float: right;
            }
        }
        .content {
            height: 100%;
            max-height: calc(100% - 80px);
            overflow-y: auto;
            .ant-tree {
                min-width: 190px;
                padding: 12px 10px 10px 0;
                .ant-tree-treenode {
                    &.drag-over>[draggable] {
                        color: inherit;
                        background-color: #fff;
                        border-bottom-color: #1890ff;
                    }
                }
            }
        }
        .filter-bottom {
            line-height: 24px;
            padding: 8px 12px 8px;
            border-top: 1px solid #f0f0f0;
        }
    }
}