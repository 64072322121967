/**
 * table cur export
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-cur-export {
    .c-table-cur-export {
        position: relative;
        top: -2px;
        @include prefixer(transform, rotate(90deg));
    }
    .c-xlsx-export {
        @include position(absolute, 0 0, 1000);
        width: 1px;
        height: 1px;
        overflow: hidden;
        opacity: 0;
        .no-print {
            display: none;
        }
    }
}