/**
 * input range
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-rich-editor {
    .bf-container {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .bf-content {
        height: 230px;
        .public-DraftEditor-content * {
            font-size: 14px;
            line-height: 22px;
        }
        .public-DraftEditorPlaceholder-root {
            font-size: 14px;
        }
    }
    .bf-controlbar {
        padding-bottom: 5px;
        padding-left: 2px;
        box-shadow: inset 0 -1px 0 0 #d9d9d9;
        .control-item {
            margin-bottom: 0;
            &:first-child {
                margin-left: 3px;
            }
        }
        .separator-line {
            margin-bottom: 5px;
        }
    }
}