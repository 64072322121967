/**
 * geolocation point
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-baidu-map-box {
    .c-baidu-map-loading {
        @include position(absolute, 15px n n 15px);
        width: 40px;
        height: 40px;
        padding: 5px;
        color: #1890ff;
        background: #fff;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px;
        @include prefixer(transform, scale(0, 0));
        @include prefixer(transition, transform 0.3s);
        &.on {
            @include prefixer(transform, scale(1, 1));
            .anticon-loading,
            .c-baidu-map-percent {
                display: block;
            }
        }
        .anticon-loading {
            display: none;
            @include position(absolute, 10px 10px n n);
            z-index: 2;
            font-size: 20px;
        }
        .c-baidu-map-percent {
            display: none;
            @include position(absolute, 4px 4px n n);
        }
    }
    .c-baidu-map-iframe {
        display: block;
        width: 100%;
        height: 450px;
        border: none;
    }
}