/**
 * file uploader
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-file-uploader {
    .ant-upload {
        .holder {
            margin-left: 12px;
            padding-top: 6px;
            color: #bfbfbf;
            pointer-events: none;
            @include prefixer(user-select, none);
        }
    }
    .ant-upload-list-item-info {
        background-color: #fcfcfc;
    }
}

.c-file-uploader-no-delete {
    .ant-upload-list-item-card-actions {
        display: none;
    }
}

.c-file-uploader-confirm {
    @include flexbox(flex, flex-start flex-start);
    padding: 6px 12px;
    background-color: #f8f8f8;
    border-radius: 6px;
    >span.anticon {
        margin: 2px 4px 0 0;
        font-size: 16px;
    }
    >span.file-name {
        word-break: break-word;
    }
}