/**
 * CQRCode
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-qr-code {
    position: relative;
    canvas {
        display: block;
    }
    img {
        @include position(absolute, 0 0);
    }
}