/**
 * helper
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-helper {
    .anticon-question-circle {
        margin-left: 3px;
        color: #a6a6a6;
    }
}