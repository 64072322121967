/**
 * text
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-text {
    &.c-text-success {
        color: $colorSuccess;
    }
    &.c-text-info {
        color: $colorInfo;
    }
    &.c-text-warning {
        color: $colorWarning;
    }
    &.c-text-danger {
        color: $colorDanger;
    }
    &.c-text-ignore {
        color: $colorIgnore;
    }
    &.c-text-link {
        color: $colorStress;
    }
}