/**
 * cEchartsPie
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-echart-pie {
    transform: translate3d(0, 0, 0);
    small {
        font-size: 12px;
    }
}