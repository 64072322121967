/**
 * image viewer
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-image-viewer-warning {
    .anticon {
        float: left;
        font-size: 20px;
        color: #faad14;
        margin-right: 8px
    }
}
.c-image-viewer-preview-single {
    font-size: 0;
    img {
        max-width: 600px;
        max-height: 600px;
        cursor: pointer;
    }
}
.c-image-viewer-preview-list {
    font-size: 0;
    img {
        max-width: 320px;
        max-height: 320px;
        margin-right: 6px;
        vertical-align: top;
        cursor: pointer;
    }
}

figure#zmage {
    #zmageControl,
    #zmageControlPagination,
    #zmageControlFlipRight,
    #zmageControlFlipLeft {
        background-color: #fff !important;
    }
    .image-size-fixed {
        @include prefixer-val(max-width, calc(100% - 20px));
        @include prefixer-val(max-height, calc(100% - 20px));
        &.zooming__8A3Sl {
            max-width: none;
            max-height: none;
        }
    }
}