/**
 * fit-height-modal
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-fit-height-modal {
    overflow: hidden;
    .ant-modal {
        margin: 0 auto;
        padding: 8px 0;
        .ant-modal-content {
            height: 100%;
            overflow: hidden;
        }
        .ant-modal-close {
            @include position(absolute, 0 n n 0, 3);
        }
        .ant-modal-header {
            @include position(absolute, 0 0, 2);
            width: 100%;
            background-color: #fff;
        }
        .ant-modal-body {
            margin: 55px 0 0;
            padding: 24px 24px 0;
            @include prefixer-val(height, calc(100% - 55px));
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    .c-fit-height-modal-inner {
        height: 100%;
        >form {
            border-bottom: transparent 1px solid;
        }
    }
}