/**
 * flat form
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-flat-form {
    &.c-flat-form-child-block {
        .c-flat-item-group {
            margin-bottom: 14px;
            .ant-form-item {
                &.c-flat-item-child {
                    display: flex;
                    min-width: auto;
                    margin-right: 0;
                    margin-bottom: 8px;
                }
                &.ant-form-item-hidden {
                    display: none;
                }
            }
        }
    }
    &.c-flat-from-child-inline {
        .c-flat-item-group {
            margin-bottom: 0;
            .c-flat-item-child {
                display: inline-block;
                min-width: 48%;
                margin-right: 2%;
            }
        }
    }
    .ant-radio-group {
        padding-left: 8px;
        &.ant-radio-group-solid {
            padding-left: 0;
        }
    }
}