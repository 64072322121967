/**
 * detail tabs model
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-detail-tabs-model {
    &.ant-modal .ant-modal-body {
        padding: 4px 5px 0;
        background-color: #f0f2f5;
    }
    .ant-tabs.ant-tabs-card {
        .ant-tabs-nav {
            margin-bottom: 0;
            .ant-tabs-tab {
                margin-right: 3px;
                border: none;
            }
        }
        .ant-tabs-content {
            padding: 17px 12px 12px;
            margin-bottom: 5px;
            background-color: #fff;
            .ant-divider {
                margin: 12px 0;
                color: rgba(0, 0, 0, 0.3);
            }
            .ant-descriptions {
                padding: 0 10px;
            }
        }
    }
}