/**
 * detail tabs model
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-draggable-modal {
    .ant-modal {
        position: relative;
        top: 0;
        left: 0;
        padding: 8px 0;
    }
    .c-draggable-modal-handler {
        @include position(absolute, 0 0);
        width: 100%;
        height: 100%;
        cursor: move;
    }
    .ant-modal-close-x {
        &:hover {
            background-color: rgba(0, 0, 0, 0.02);
        }
    }
}