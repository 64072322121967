/**
 * image uploader
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-image-uploader {
    .ant-upload-select-picture {
        @include prefixer(pointer-events, none);
        .ant-upload {
            @include flexbox(flex, flex-start);
            input,
            button {
                @include prefixer(pointer-events, auto);
            }
            .holder {
                margin-left: 10px;
                padding-top: 6px;
                color: #bfbfbf;
                pointer-events: none;
                @include prefixer(user-select, none);
            }
            .anticon-cloud-upload {
                font-size: 17px;
                vertical-align: middle;
            }
        }
    }
    .ant-upload-list-picture {
        .ant-upload-list-item {
            min-width: 150px;
            margin-top: 6px;
        }
        .ant-upload-list-item-card-actions {
            top: 0;
            height: 100%;
        }
        .ant-upload-list-item-card-actions-btn {
            height: 100%;
            width: 30px;
            text-align: center;
            line-height: 66px;
            border-left: #d9d9d9 1px solid;
            background-color: #fafafa;
            &:hover {
                background-color: #f5f5f5;
                .anticon-delete {
                    color: #f5222d;
                }
            }
            .anticon-delete {
                font-size: 15px;
                padding: 0;
                color: rgba(#000, 0.65);
            }
        }
        .ant-upload-list-item-progress {
            padding-right: 25px;
        }
        .ant-upload-list-item-name {
            cursor: pointer;
        }
    }
}