/**
 * mixins
 * @author Tevin
 */

/* ---------- color variables ---------- */

$appActiveColor: #1890ff;
$appActiveGround: #f5f5f5;
$appErrorColor: #f5222d;
$appErrorGround: #fa7d64;

$BorderColorLight:#eee;
$BorderColorNormal: #e5e5e5;
$BorderColorStress: #d9d9d9;

$colorStress: #2093df;
$colorSuccess: #22c322;
$colorInfo: #04c1c8;
$colorWarning: #FFB800;
$colorDanger: #FF5722;
$colorIgnore: rgba(0, 0, 0, 0.45);

/* ---------- click active ---------- */

@mixin click-active($active:$appActiveColor, $default:#fff) {
    background-color: $default;
    @include prefixer(user-select, none);
    cursor: pointer;
    &:hover {
        color: $active;
    }
    &:active {
        background-color: $appActiveGround;
    }
}

@mixin hover-active($active:$appActiveColor, $default:#fff) {
    background-color: $default;
    @include prefixer(user-select, none);
    cursor: pointer;
    &:hover {
        color: $active;
        background-color: $appActiveGround;
    }
}

/* ---------- transition ---------- */
@mixin transition($time:0.2s, $rate:ease-in-out) {
    @if ($rate=="ease-in-out-2") {
        $rate: cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    -webkit-transition: all $time $rate;
    -moz-transition: all $time $rate;
    transition: all $time $rate;
}

/* ---------- clearfix ---------- */
@mixin clearfix() {
    &:after {
        clear: both;
        display: block;
        width: 100%;
        height: 0px;
        overflow: hidden;
        content: " ";
    }
}

/* ---------- ellipsis ---------- */
@mixin ellipsis($width:false) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @if ($width) {
        width: $width;
    }
}

/* ---------- prefixer ---------- */
@mixin prefixer ($property, $value...) {
    -webkit-#{$property}: $value;
    -moz-#{$property}: $value;
    #{$property}: $value;
}

@mixin prefixer-val ($property, $value) {
    #{$property}: -webkit-#{$value};
    #{$property}: -moz-#{$value};
    #{$property}: #{$value};
}

/* ---------- position ---------- */
@mixin position($type, $values:(), $zindex:false) {
    position: $type;
    // 1个值：上
    @if (length($values)==1) {
        @if (nth($values, 1) !=n) {
            top: nth($values, 1);
        }
    }
    // 2个值：上、左
    @else if (length($values)==2) {
        @if (nth($values, 1) !=n) {
            top: nth($values, 1);
        }
        @if (nth($values, 2) !=n) {
            left: nth($values, 2);
        }
    }
    // 3个值：上、左右、下
    @else if (length($values)==3) {
        @if (nth($values, 1) !=n) {
            top: nth($values, 1);
        }
        @if (nth($values, 2) !=n) {
            right: nth($values, 2);
            left: nth($values, 2);
        }
        @if (nth($values, 3) !=n) {
            bottom: nth($values, 3);
        }
    }
    // 4个值：上、左、下、右
    @else if (length($values)==4) {
        @if (nth($values, 1) !=n) {
            top: nth($values, 1);
        }
        @if (nth($values, 2) !=n) {
            left: nth($values, 2);
        }
        @if (nth($values, 3) !=n) {
            bottom: nth($values, 3);
        }
        @if (nth($values, 4) !=n) {
            right: nth($values, 4);
        }
    }
    @if ($zindex) {
        z-index: $zindex;
    }
}

/**
 * ---------- flex ---------- 
 * @include flexbox();
 *   $mode:
 *     flex / inline
 *   $align: <justify-content> <align-items> <align-content>
 *     flex-start / flex-end / center / space-between / space-around
 *     flex-start / flex-end / center / baseline / stretch
 *     flex-start / flex-end / center / space-between / space-around / stretch
 *   $flow: <flex-direction> <flex-wrap>
 *     row / row-reverse / column / column-reverse
 *     nowrap / wrap / wrap-reverse
 */
@mixin flexbox ($mode:flex, $align:(), $flow:()) {
    @if ($mode=="flex") {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: flex;
    }
    @else if($mode=="inline") {
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -moz-inline-flex;
        display: inline-flex;
    }
    @if (length($align)>=1) {
        @if (nth($align, 1) !=n) {
            -webkit-justify-content: nth($align, 1);
            -moz-justify-content: nth($align, 1);
            justify-content: nth($align, 1);
        }
    }
    @if(length($align)>=2) {
        @if (nth($align, 2) !=n) {
            -webkit-align-items: nth($align, 2);
            -moz-align-items: nth($align, 2);
            align-items: nth($align, 2);
        }
    }
    @if(length($align)>=3) {
        @if (nth($align, 3) !=n) {
            -webkit-align-content: nth($align, 3);
            -moz-align-content: nth($align, 3);
            align-content: nth($align, 3);
        }
    }
    @if (length($flow)>=1) {
        @if (nth($flow, 1) !=n) {
            -webkit-flex-direction: nth($flow, 1);
            -moz-flex-direction: nth($flow, 1);
            flex-direction: nth($flow, 1);
        }
    }
    @if (length($flow)>=2) {
        @if (nth($flow, 2) !=n) {
            -webkit-flex-wrap: nth($flow, 2);
            -moz-flex-wrap: nth($flow, 2);
            flex-wrap: nth($flow, 2);
        }
    }
}

/* ---------- keyframes ---------- */
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

/* ---------- media ---------- */
@mixin media($type, $only:"") {
    $title: "";
    // 打印媒体
    @if ($type=="print") {
        $title: $title + "print";
    }
    // 显示器媒体
    @else {
        $title: $title + "screen";
        @if ($type=="pc") {
            // 仅电脑
            @if ($only=="only") {
                $title: $title + " and (min-width: 992px)";
            }
            // 所有设备
            @else {}
        }
        @else if ($type=="padpro") {
            // 仅大平板
            @if ($only=="only") {
                $title: $title + " and (min-width: 768px) and (max-width: 992px)";
            }
            // 所有平板和手机
            @else {
                $title: $title + " and (max-width: 992px)";
            }
        }
        @else if ($type=="padmini") {
            // 仅小平板
            @if ($only=="only") {
                $title: $title + " and (min-width: 480px) and (max-width: 768px)";
            }
            // 小平板和手机
            @else {
                $title: $title + " and (max-width: 768px)";
            }
        }
        @else if ($type=="phonepro") {
            // 仅大屏手机
            $title: $title + " and (max-width: 575px)";
        }
        @else if ($type=="phone") {
            // 仅手机
            $title: $title + " and (max-width: 480px)";
        }
    }
    @media #{$title} {
        @content;
    }
}