/**
 * grid-box
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-grid-row {
    .c-grid-col {
        &.no-style {
            >.c-grid-box-box {
                border-radius: 0;
                background: none;
                >.c-grid-box-content {
                    padding: 0;
                }
            }
        }
    }
    .c-grid-box-box {
        background-color: #fff;
        border-radius: 2px;
    }
    .c-grid-box-top {
        padding: 0 15px;
        line-height: 39px;
        border-bottom: #eee 1px solid;
        @include clearfix;
    }
    .c-grid-box-sub {
        float: right;
    }
    .c-grid-box-content {
        padding: 15px;
    }
}