/**
 * notice
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-notice {
    margin-top: 10px;
    margin-bottom: 10px;
    ul {
        list-style: disc;
        padding-top: 8px;
        padding-left: 20px;
        li {
            padding-bottom: 6px;
            strong.c-notice-subhead {
                display: block;
                font-weight: normal;
            }
            p {
                padding-left: 14px;
                line-height: 24px;
                big {
                    float: left;
                }
                span.c-notice-chilren {
                    display: block;
                    margin-left: 16px;
                }
            }
        }
    }
}