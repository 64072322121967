/**
 * input number step
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-input-num-step {
    display: inline-block;
    .step-left {
        float: left;
        width: 31px;
        height: 32px;
        padding: 0;
        line-height: 32px;
        border: #d9d9d9 1px solid;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .ant-input {
        float: left;
        position: relative;
        z-index: 2;
        border-radius: 0;
        @include prefixer-val(width, calc(100% - 62px));
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        text-align: center;
    }
    .step-right {
        float: left;
        width: 31px;
        height: 32px;
        padding: 0;
        line-height: 32px;
        border: #d9d9d9 1px solid;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .anticon-plus,
    .anticon-minus {
        color: #666;
        font-size: 12px;
    }
}