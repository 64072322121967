/**
 * item editor
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-printer-print {
    display: inline;
    .c-printer-print-ground {
        @include position(absolute, 0 0);
        width: 0;
        height: 1px;
        overflow: hidden;
    }
    .c-printer-iframe-print {
        padding: 0;
        border: none;
    }
}

.c-printer-privew-modal {
    &.ant-modal .ant-modal-body {
        padding: 0;
    }
    .c-draggable-modal-title {
        text-indent: -12px;
    }
    .c-printer-iframe-preview {
        display: block;
        width: 100%;
        min-height: 96%;
        border: none;
        &.on-footer {
            padding: 0 0 54px;
        }
    }
    .c-printer-preview-footer {
        @include position(absolute, n 0 0 n);
        width: 100%;
        height: 60px;
        background-color: #fff;
        border-top: 1px solid #f0f0f0
    }
}